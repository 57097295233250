<template>
    <main>
        <page-header-compact>
      Laboratorios Urgencias
    </page-header-compact>
    <div class="container-fluid">
        <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="small font-weight-bolder">Fecha</label>
                    <input v-model="pagination.params.fecha" class="form-control form-control-sm" type="date">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-auto">
                  <div class="form-group">
                    <button class="btn btn-info mt-2 btn-sm shadow-sm" @click="search()">
                      <i class="fa fa-filter fa-fw"></i> Filtar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <data-grid :data="pagination" :search-box="true" @search="search">
                <template #grid>
                  <!-- bs4 list-group -->
                  <ul class="list-group mb-2">
                    <li v-for="(orden, i) in ordenes" :key="`${i}_agenda_data_row`"
                       
                        class="list-group-item flex-column align-items-start"
                    >
                      <div class="d-flex w-100 justify-content-between">
                        <h6 class="mb-1">
                          <strong>
                            <i class="fas fa-calendar fa-fw"></i>
                            {{ orden.created_at | formatDateTime }}
                            <!-- <span
                                class="ml-2 badge badge-light badge-marketing rounded-pill font-weight-bolder">
                              <i class="fas fa-file-medical fa-fw"></i>
                              {{ orden.nombreTipoCita }}
                            </span> -->
                          </strong>
                          <small class="d-block font-weight-bolder mt-1"> <i
                              class="fas fa-user fa-fw"></i> ({{ orden.documento }}) {{ orden.usuario }}
                            | {{ orden.edad }}</small>
                        </h6>
                        <div>
                          <template v-if="orden.countWorkOrders">
                            <small v-if="orden.workOrderInfo.resultado" class="font-weight-bolder text-success">Resultados registrados</small>
                          </template>
                          <span class="badge badge-marketing badge-primary ml-2 mr-2 cursor-pointer" v-if="orden.countWorkOrders"
                            @click="printTicket(orden.workOrderId)">
                            <i class="fas fa-flask fa-fw"></i>
                            {{ orden.workOrderInfo.toma_muestra }}
                          </span>
                          <!-- <span :key="orden.id" :class="mapAppointmentState(orden.estado).badgeClass">
                                            <i :class="mapAppointmentState(orden.estado).badgeIcon"></i>
                                            {{ `${orden.estado}`.toUpperCase() }}
                          </span> -->
                          <!-- si tiene resultados -->
                        </div>
                      </div>
                      <div class="d-flex w-100 justify-content-between">
                        <p class="mb-1 font-weight-bolder">
                          Profesional y Sede :
                          <i class="fas fa-user-md fa-fw"></i>
                          {{ orden.profesional }}
                          |
                          <i class="fas fa-hospital fa-fw"></i>
                          {{ orden.sede }}
                        </p>
                        <div>
                          <!-- manejo de ordens -->
                          <button
                              v-if="orden.countWorkOrders === 0"
                              class="btn btn-info btn-icon shadow-sm btn-sm mr-2"
                              title="Orden de Trabajo"
                              @click="openWorkOrderModal(orden.id_usuario, orden.ordenClinicaId)"
                          >
                            <!-- laboratory fontawesome icon -->
                            <i class="fas fa-flask fa-fw"></i>
                          </button>
                          <button
                              v-if="orden.countWorkOrders > 0"
                              class="btn btn-info btn-icon shadow-sm btn-sm mr-2"
                              title="Orden de Trabajo"
                              @click="edit(orden.workOrderInfo.id)"
                          >
                            <!-- edit fontawesome icon -->
                            <i class="fas fa-edit fa-fw"></i>
                          </button>
                          <router-link
                              :to="{
                                name: 'resultados.laboratorio.create',
                                params: {
                                  workOrderId: orden.workOrderId
                                },
                                query: {
                                  orderDate: orden.fecha,
                                }
                              }"
                              target="_blank"
                              v-if="orden.countWorkOrders > 0"
                              class="btn btn-primary btn-icon shadow-sm btn-sm"
                              title="LLenar Resultados"
                          >
                            <!-- laboratory fontawesome icon -->
                            <i class="fas fa-flask fa-fw"></i>
                          </router-link>
                        </div>
                      </div>
                    </li>
                  </ul>
                </template>
              </data-grid>
            </div>
          </div>
        </div>
      </div>
      <modal-orden-trabajo ref="modalOrdenTrabajo" v-on:saved="handleSaved" v-on:ticket="printTicket"></modal-orden-trabajo>
      </div>
    
    </main>
</template>
<script>
import PageHeaderCompact from "@/components/layouts/content/page-header-compact.vue";
import paginateMixin from "@/mixins/PaginateMixin";
import laboratoriosUrgenciaService from "@/services/laboratoriosUrgenciaService";
import DataGrid from "@/components/common/utilities/DataGrid/DataGrid.vue";
import dayjs from "dayjs";
import ModalOrdenTrabajo from "@/views/historias/base/baseOrdenTrabajo/modalOrdenTrabajo.vue";
import ordenTrabajoService from "@/services/ordenTrabajoService";
import Toast from '@/components/common/utilities/toast';

export default {
    components: {ModalOrdenTrabajo, DataGrid, PageHeaderCompact},
    mixins: [paginateMixin(laboratoriosUrgenciaService)],
    data(){
        return {
            pagination: {
                params: {
                    fecha: dayjs().format('YYYY-MM-DD')
                }
            }
        }
    },
    methods:{
      openWorkOrderModal(id_usuario, id_orden_clinica) {
        this.$refs.modalOrdenTrabajo.open(id_usuario,null, id_orden_clinica);
      },
      edit(id) {
        this.$refs.modalOrdenTrabajo.edit(id);
      },
      async handleSaved() {
        this.index();
      },
      async printTicket(id_orden_trabajo) {
        try {
          //window.open(await ordenTrabajoService.printTicket(id_orden_trabajo),'_blank', 'left=100,top=100,width=500,height=500');
          const pdfUrl = await ordenTrabajoService.printTicket(id_orden_trabajo);
          const zoomedPdfUrl = `${pdfUrl}#zoom=150`;
          window.open(zoomedPdfUrl, '_blank', 'left=100,top=100,width=500,height=500');
        }catch (e) {
          console.error(e);
          Toast.fire({
            icon: 'error',
            title: 'Ocurrio un error al generar el ticket'
          });
        }
      }
    },
    computed: {
        ordenes: function () {
            return this.pagination.data.map(orden => {
                return {
                'ordenClinicaId': orden.id,
                'id': `${orden.id}`.padStart(4, '0'),
                'id_usuario': orden.historia.usuario.id,
                'edad': `${orden.historia.usuario.years}`,
                'documento': `${orden.historia.usuario.tipo_documento.codigo} ${orden.historia.usuario.documento}`,
                'usuario': `${orden.historia.usuario.nombre_1} ${orden.historia.usuario.nombre_2 || ''} ${orden.historia.usuario.apellido_1} ${orden.historia.usuario.apellido_2 || ''}`.toUpperCase(),
                'sede': `${orden.historia.sede.NOMBRE_SEDE}`.toUpperCase(),
                'created_at':orden.created_at,
                //'estado': `${orden.estado}`,
                'fecha': dayjs(orden.created_at).format('YYYY-MM-DD'),
                'profesional': `${orden.historia.user.name}`.toUpperCase(),
                'countWorkOrders' : orden.ordenes_trabajo.length,
                'workOrderId' : orden.ordenes_trabajo.length > 0 ? orden.ordenes_trabajo[0].id : null,
                'workOrderInfo' : orden.ordenes_trabajo.length > 0 ? orden.ordenes_trabajo[0] : null,
                }
            });
        }
    },
    created() {
        this.index();
    },
}
</script>
<style scoped>
  .cursor-pointer{
    cursor: pointer;
  }
</style>